import React, {useEffect, useState} from 'react';
import axios from 'axios';
import Button from 'components/Button'
import {Link} from 'react-router-dom'

import './style.scss';

const urlRules = 'https://pay.audi.ru/'

const AfterOrder = (props) => {
    const [dealerItem, setDealerItem] = useState()
    const [orderId, setOrderId] = useState('')
    const [orderEmail, setOrderEmail] = useState('') 
    const [parentId, setPartnerId] = useState('')
    const [modelName, setModelName] = useState('')
    const { failed } = props;    
 
    const model = localStorage.getItem('model') || 'Audi';

    useEffect(_=> {
        const strGET = window.location.search.replace( '?', '')  
        let getIdPartner  
        let dealer
        function getQueryParams() {
            return failed 
            ? axios.get(`/api/fail?${strGET}`)
            : axios.get(`/api/success?${strGET}`)
          }
                    
          function getDealers() {
            return axios.get(`/api/dealers`);
          }
                      
          axios.all([getQueryParams(), getDealers()])          
            .then(axios.spread(function (queryParams, dealers) {            
                if(queryParams.status === 200) {
                    getIdPartner = queryParams.data.partner_id
                    setPartnerId(getIdPartner)
                    dealer = dealers.data.payload.filter(c => c.partner_id === getIdPartner)
                    setOrderId(queryParams.data.order_id)
                    setOrderEmail(queryParams.data.email)                       
                    if(!dealerItem) {
                        setDealerItem(...dealer)   
                    }                                          
                }                         
          }));  

          if(!failed) {       
            axios.get (`/api/preorder/${model}`)
            .then (res => {
              setModelName(res.data.payload.model_name)              
            });  
          }
                    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <section className="after-order-wrapper">            
            <div className="after-order">
                <div className="after-order-container">
                    <h1 className="after-order-title">
                        {failed ? 'Что-то пошло не так!' : 'Поздравляем!'}
                    </h1>
                    <p className="after-order-subtitle">
                        {failed ? 'К сожалению, не удалось забронировать автомобиль.' : `Ваш заказ №${orderId} принят.`}
                    </p>
                    <p className="after-order-text">
                        {failed
                            ? (
                                <span>
                                    Возникли проблемы при оплате бронирования. Повторите попытку еще раз, <br/>
                                    или свяжитесь с&nbsp;дилером для уточнения деталей.
                                </span>
                            )
                            : (
                                <span>
                                    Вы&nbsp;успешно оформили предзаказ на {modelName}. <br/>
                                    Полная информация о&nbsp;платеже и&nbsp;дальнейшие инструкции отправлены на&nbsp;<b>{orderEmail}.</b> <br/>
                                    В&nbsp;ближайшее время представитель дилерского центра свяжется с&nbsp;вами для уточнения деталей.
                                </span>
                            )
                        }
                    </p>
                    {dealerItem ?  <div className="after-order-dealer">
                        <div className="after-order-dealer__left">
                    <div className="after-order-dealer__city">{dealerItem.city}</div>
                    <div className="after-order-dealer__name">{dealerItem.name}</div>
                        </div>
                        <div className="after-order-dealer__right">
                            <a href={`tel:${dealerItem.phone}`} className="after-order-dealer__tel" type="tel">{dealerItem.phone} </a>
                    <div className="after-order-dealer__address">{dealerItem.address}</div>
                        </div>
                    </div> : null}
                   
                    <p  hidden={failed} className="after-order-text after-order-text_more">
                        Узнайте подробнее о&nbsp;том, как это работает или ознакомьтесь с&nbsp;правилами бронирования и&nbsp;часто задаваемыми вопросами.
                    </p>
                    {failed                     
                    ? 
                    <Link to={`/${model}`}>
                        <Button className="after-order-how-btn">
                            Попробовать еще раз
                        </Button>
                    </Link>                    
                    : null }
                    
                    <div className={`after-order-links ${failed ? 'hidden' : ''}`}>
                        <a href={`${urlRules}rules/${parentId}`} className="after-order-link"><img src={require('images/online-rules-icon.svg')} alt=""/>Правила онлайн-бронирования</a>
                        {/* <a href="/" className="after-order-link"><img src={require('images/question-icon.svg')} alt=""/>Часто задаваемые вопросы</a> */}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AfterOrder
