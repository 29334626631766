import React from 'react';
import classNames from 'classnames';

import './style.scss';

export const Tabs = (props) => {
    //ClassNames
    const className = classNames(
        'tabs',
        props.className
    )

    return (
        <div className={className}>
            {props.children}
        </div>
    )
}

export const Tab = (props) => {
    //ClassNames
    const className = classNames(
        'tabs__item',
        { active: props.active }
    )

    //Data
    const propsClone = {
        ...props,
        active: Number(props.active)
    };

    return (
        <label className={className}>
        <input {...propsClone} checked={propsClone.active} type="checkbox" className="tabs__input" children={null}  />
            <div className="tabs__item-inner">{props.label}</div>
            {props.children}
        </label>
    )
}
