export const validateRegExp = {
    "email": /^[-a-z0-9~!$%^&*_=+}{'?]+(\.[-a-z0-9~!$%^&*_=+}{'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i,
    "tel": /^((\+8|\+7|8)[- ]?)(\(?\d{3}\)?[- ]?)?[\d\- ]{7,10}$/,
    "inn": /^[0-9]{10,12}$/,
    "bik": /^[0-9]{9}$/,
    "correspondent_account": /^[0-9]{20}$/,
    "payment_account": /^[0-9]{20}$/,
    "text": "",
    "number": /^[0-9]{1,}$/,
    "date": /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/,
    "year": /^(19|20)\d{2}$/,
    "vin": "^[^\\Wioq]{17}$",
    "name": "^[-'a-z A-Z а-я А-Я]+$",
    "lastname": "^[-'a-z A-Z а-я А-Я]+$",    
};

export const validateValue = (value, type) => {
    if (value && type) {
        return String(value).match(validateRegExp[type])
    }
}

export const validateInput = (value, type) => {
    let inputValid;

    if (Array.isArray(type)) {
        inputValid = false;
        type.forEach(typeItem => {
            if (validateValue(value, typeItem)) {
                inputValid = true;
            }
        })
    } else if (typeof type === 'string') {
        inputValid = !!validateValue(value, type);
    } else {
        console.error('Wrong "type" format in validateInput(). It must be a string or an array.')
        return true
    }

    return inputValid
}
