import React, { useState, Fragment, useEffect } from "react";
import axios from "axios";
import { useRouteMatch, Redirect } from "react-router-dom";
import { typograf } from "utils";

import { Tabs, Tab } from "components/Tabs";
import RequestForm from "./RequestForm";
import PreorderDealer from "./PreorderDealer";

import "./style.scss";
import dataLayerHandlers from "utils/dataLayerHandlers";

const Form = ({ dealerCityMos }) => {
  //State
  const [bannerImage, setBannerImage] = useState("");
  const [title, setTitle] = useState("");
  const [modelId, setModelId] = useState("");

  const [isDealerRequest, setIsDealerRequest] = useState(false);
  const [modelIsNotFound, setModelIsNotFound] = useState(false);
  const [requestDealer, setRequestDealer] = useState();
  const [preorderDealer, setPreorderDealer] = useState();

  const [allDealersMy, setAllDealersMy] = useState([]);
  const [modelData, setModelData] = useState(null);

  const { model } = useRouteMatch("/:model").params;

  useEffect(() => {
    axios.get("/api/dealers").then((res) => setAllDealersMy(res.data.payload));
    axios
      .get("/api/preorder/" + model)
      .then((res) => {
        const { img, name, model_id } = res.data.payload;

        setBannerImage(img);
        setTitle(name);
        setModelId(model_id);
        setModelData(res.data.payload);
      })
      .catch((error) => {
        console.error(error);

        setModelIsNotFound(true);
      });

    dataLayerHandlers.open("PreorderForm", model);
  }, [model]);

  useEffect(() => {
    localStorage.setItem("model", model);
  }, [model]);

  useEffect(() => {
    if (modelData && allDealersMy.length) {
      let filteredPreorderDealers = dealerCityMos
        ? [allDealersMy.find((item) => item.id === 25555)]
        : allDealersMy.reduce((acc, curr) => {
            if (modelData.dealers.includes(curr.id)) {
              acc.push(curr);
            }

            return acc;
          }, []);

      let allCities = {};
      let onlinePayCities = {};

      filteredPreorderDealers.forEach((dealer) => {
        if (dealer.exist) {
          if (!(dealer.city in onlinePayCities)) {
            onlinePayCities[dealer.city] = [];
          }
          onlinePayCities[dealer.city].push({
            label: dealer.name,
            value: dealer.partner_id,
          });
        }
      });

      allDealersMy.forEach((dealer) => {
        if (!(dealer.city in allCities)) {
          allCities[dealer.city] = [];
        }
        allCities[dealer.city].push({
          label: dealer.name,
          value: dealer.partner_id,
        });
      });

      setPreorderDealer(onlinePayCities);

      if (dealerCityMos) {
        setRequestDealer(onlinePayCities);
      } else {
        setRequestDealer(allCities);
      }
    }
  }, [allDealersMy, dealerCityMos, modelData]);

  //Form validation

  const onTabChange = (e) => {
    if (e.target.value === "preorder") {
      setIsDealerRequest(false);
      dataLayerHandlers.open("PreorderForm", model);
    } else {
      setIsDealerRequest(true);
      dataLayerHandlers.open("RequestForm", model);
    }
  };

  return modelIsNotFound ? (
    <Redirect push to="/" />
  ) : (
    <Fragment>
      {bannerImage && (
        <div
          className="form-banner"
          style={{ backgroundImage: `url(${bannerImage})` }}
        />
      )}
      <h1 className="form-title">{typograf(title)}</h1>
      <Tabs className="form-tabs">
        <Tab
          onChange={onTabChange}
          value="preorder"
          active={!isDealerRequest}
          label="Предзаказ"
        >
          <div className="form-tabs__check">
            <img src={require("images/check_white.svg")} alt="" />
          </div>
        </Tab>
        <Tab
          onChange={onTabChange}
          value="dealer-request"
          active={isDealerRequest}
          label="Запросить информацию"
        />
      </Tabs>
      {/* {!isDealerRequest ? <TopFormInfo prepayCount={prepayCount} bannerImage={bannerImage} /> : null} */}

      {!isDealerRequest ? (
        <PreorderDealer
          isDealerRequest={isDealerRequest}
          preorderDealer={preorderDealer}
          modelId={modelId}
          allDealers={allDealersMy}
          dealerCityMos={dealerCityMos}
          model={model}
        />
      ) : (
        <RequestForm
          isDealerRequest={isDealerRequest}
          requestDealer={requestDealer}
          modelId={modelId}
          allDealers={allDealersMy}
          dealerCityMos={dealerCityMos}
          model={model}
        />
      )}
    </Fragment>
  );
};

export default Form;
