import { sendEventToDataLayer } from "./dataLayerEvent";

export default {
  open: (eventCategory, model) => {
    sendEventToDataLayer(eventCategory, "open", model);
  },
  sex: (eventCategory, sex) => {
    sendEventToDataLayer(eventCategory, "sex", sex);
  },
  inputName: (eventCategory, model) => {
    sendEventToDataLayer(eventCategory, "input_name", model);
  },
  inputLastname: (eventCategory, model) => {
    sendEventToDataLayer(eventCategory, "input_lastname", model);
  },
  inputPhone: (eventCategory, model) => {
    sendEventToDataLayer(eventCategory, "input_phone", model);
  },
  inputEmail: (eventCategory, model) => {
    sendEventToDataLayer(eventCategory, "input_email", model);
  },
  inputContacts: (eventCategory, model) => {
    sendEventToDataLayer(eventCategory, "input_contacts", model);
  },
  selectCity: (eventCategory, city) => {
    sendEventToDataLayer(eventCategory, "select_city", city);
  },
  selectDealer: (eventCategory, dealer) => {
    sendEventToDataLayer(eventCategory, "select_dealer", dealer);
  },
  confidential: (eventCategory, answer) => {
    sendEventToDataLayer(eventCategory, "confidential", answer);
  },
  advertisement: (eventCategory, answer) => {
    sendEventToDataLayer(eventCategory, "advertisement", answer);
  },
  submit: (eventCategory, model) => {
    sendEventToDataLayer(eventCategory, "submit", model);
  },
  success: (eventCategory, model) => {
    sendEventToDataLayer(eventCategory, "success", model);
  },
};
