import React from 'react'

const StyleBlock = {
  maxWidth: '478px',
  margin: 'auto',
  padding: '0 20px 120px'
}



const RequestStatus = ({status, setChangeComponent}) => {  
  const heandleReload = () => {
   setChangeComponent(true)
 }
      return (
        <div  style={StyleBlock}>
          <h2 style={{textAlign: 'center'}}>            
          {status ? 'Ваш запрос успешно отправлен' : 'При отправки запроса произошла ошибка.'}
          </h2>
          { status ?  '' : <button onClick={heandleReload} className='button form__submit'>Попробовать ещё раз</button>}
        </div>
      ) 
}

export default RequestStatus