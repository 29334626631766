import React from "react";
import classNames from "classnames";

import "./style.scss";

const Radio = (props) => {
  //ClassNames
  const className = classNames(
    "radio",
    props.className,
    { error: props.error },
    { hidden: props.hidden }
  );

  return (
    <label className={className}>
      <input
        {...props}
        error=""
        children={null}
        className="radio__input"
        type="radio"
      />
      <div className="radio__label"></div>
      {props.children}
    </label>
  );
};

export default Radio;
