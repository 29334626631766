import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Header from 'components/Header';

import Form from 'pages/Form/index';
import AfterOrder  from 'pages/AfterOrder';
import Rules from 'pages/Rules/index';
import StatusPage from 'pages/Status/index';


import './style.scss'


function App() {
    return (
        <BrowserRouter>
            <Header />       
            <Switch>
                <Route path="/success" exact>
                    <AfterOrder />
                </Route>
                <Route path="/fail" exact>
                    <AfterOrder failed />
                </Route>
                <Route path="/status" exact>
                    <StatusPage />
                </Route>   
                <Route path="/:model" exact> 
                    <Form />
                </Route>   
                <Route path="/sq8/dealer-25555" exact> 
                    <Form dealerCityMos={true}/>
                </Route>                                         
                <Route path="/rules/:dealerId" exact>
                    <Rules />
                </Route>                
                <Route
                    path="*"
                    render={() => {
                        window.location.href = "https://www.audi.ru/";
                        return null;
                    }}
                />
            </Switch>            
        </BrowserRouter>
    );
}

export default App;
