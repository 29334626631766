import React, { useEffect, useState } from "react";
import Checkbox from "components/Checkbox";
import dataLayerHandlers from "utils/dataLayerHandlers";

const FormCheckbox = ({
  item,
  setCommonValue,
  selectDealer,
  setIsPersonalDataDisclaimerVisible,
  setIsCommunicationDislaimerVisible,
  isPersonalDataDisclaimerVisible,
  isCommunicationDislaimerVisible,
  isDealerRequest,
  commonValue,
  checkValidate,
}) => {
  const [booleanState, setBooleanState] = useState(false);
  const onCheckboxChange = (e) => {
    const { checked, name } = e.target;
    setCommonValue((state) => ({
      ...state,
      [item]: { value: checked, validate: checked },
    }));
    if (name === "communication") {
      setCommonValue((state) => ({
        ...state,
        [item]: { value: checked, validate: true },
      }));
    }

    if (name === "communication") {
      dataLayerHandlers.advertisement(
        isDealerRequest ? "RequestForm" : "PreorderForm",
        checked ? "yes" : "no"
      );
    } else {
      dataLayerHandlers.confidential(
        isDealerRequest ? "RequestForm" : "PreorderForm",
        checked ? "yes" : "no"
      );
    }
  };

  const togglePersonalDataDisclaimer = (e) => {
    e.preventDefault();
    setIsCommunicationDislaimerVisible(false);
    setIsPersonalDataDisclaimerVisible(!isPersonalDataDisclaimerVisible);
  };
  const toggleCommunicationDislaimer = (e) => {
    e.preventDefault();
    setIsPersonalDataDisclaimerVisible(false);
    setIsCommunicationDislaimerVisible(!isCommunicationDislaimerVisible);
  };

  const onRulesClick = (e) => {
    e.preventDefault();
    if (selectDealer.value) {
      const newTab = window.open(
        window.location.origin + "/rules/" + selectDealer.value,
        "_blank"
      );
      newTab.focus();
    }
  };

  useEffect(
    (_) => {
      if (checkValidate) {
        commonValue["person"].validate
          ? setBooleanState(false)
          : setBooleanState(true);
      }
    },
    [checkValidate, commonValue]
  );

  return (
    <Checkbox
      error={item === "communication" ? false : booleanState}
      onChange={onCheckboxChange}
      className="form-checkbox"
      checked={commonValue[item].validated}
      name={item}
    >
      {item !== "communication" ? (
        <div className="form-checkbox__text">
          Согласие на&nbsp;
          <span
            className="form-checkbox__link"
            onClick={togglePersonalDataDisclaimer}
          >
            обработку персональных данных
          </span>
          {!isDealerRequest ? (
            <span>
              &nbsp;и&nbsp;на&nbsp;
              <span className="form-checkbox__link" onClick={onRulesClick}>
                правила онлайн-бронирования{" "}
              </span>{" "}
            </span>
          ) : (
            ""
          )}
        </div>
      ) : (
        <div className="form-checkbox__text">
          Согласие на&nbsp;
          <span
            className="form-checkbox__link"
            onClick={toggleCommunicationDislaimer}
          >
            коммуникацию со&nbsp;стороны дилерского центра и&nbsp;Ауди Россия
          </span>
        </div>
      )}
    </Checkbox>
  );
};

export default FormCheckbox;
