import React, { useState } from 'react';
import Select from 'react-select'
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Input from 'components/Input';

import './style.scss';

const CustomSelect = (props) => {
    let { value, isDisabled } = props;    
    //State
    const [isSelected, setIsSelected] = useState(false);
    const [isFocused, setIsFocused] = useState(false);

    //ClassNames
    const className = classNames(
        'select',
        props.className,
        { error: props.error && !isFocused }
    );

    //Handlers
    const onFocus = (e) => {
        setIsFocused(true);
        if (typeof props.onFocus === 'function') {
            props.onFocus(e);
        }
    }
    const onBlur = (e) => {
        setIsFocused(false);
        if (typeof props.onBlur === 'function') {
            props.onBlur(e);
        }
    }

    //Data
    if (!props.value && isSelected) {
        setIsSelected(false);
    }
    if (props.value && !isSelected) {
        setIsSelected(true);
    }
    if (!props.value) {
        value = null;
    }
    const inputProps = {
        tabIndex: '-1'
    }

    return (
        <div hidden={props.hidden} className={className}>
            <Input
                className="select__label-simulator"
                inputProps={inputProps}
                label={props.label}
                value={isSelected ? ' ' : ''}
                error={isFocused ? false : props.error}
                helperText={props.helperText}        
            />
            <Select
                {...props}
                value={value}
                classNamePrefix="select"
                placeholder=""
                isSearchable={false}
                onFocus={onFocus}
                onBlur={onBlur}
                isDisabled={isDisabled}           
            />
        </div>
    )
}

CustomSelect.propTypes = {
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
}

export default CustomSelect
