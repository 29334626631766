import React, { useState } from "react";
import Radio from "components/Radio";

import dataLayerHandlers from "utils/dataLayerHandlers";

import "./style.scss";

const FormRadio = ({ setCommonValue, isDealerRequest }) => {
  const [sex, setSex] = useState("М");

  const onRadioChange = (e) => {
    const { value } = e.target;

    setSex(value);
    setCommonValue((state) => ({
      ...state,
      sex: { value, validate: true },
    }));

    dataLayerHandlers.sex(
      isDealerRequest ? "RequestForm" : "PreorderForm",
      value
    );
  };

  return (
    <div className="form__input sex">
      <Radio
        onChange={onRadioChange}
        className="form-radio"
        checked={sex === "М"}
        value="М"
        name="sex"
      >
        <div class="form-radio__text">Г-н</div>
      </Radio>
      <Radio
        onChange={onRadioChange}
        className="form-radio"
        checked={sex === "Ж"}
        value="Ж"
        name="sex"
      >
        <div class="form-radio__text">Г-жа</div>
      </Radio>
    </div>
  );
};

export default FormRadio;
