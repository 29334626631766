import React from 'react';

import './style.scss';


const Header = (props) => {
    return (
        <header className="header">
            <div className="header-container">
                <a href="https://www.audi.ru/" className="header__link">
                    Назад на сайт audi.ru
                </a>
                <a href="https://www.audi.ru/" className="header__logo">
                    <img src={require('images/logo.svg')} alt="" />
                </a>
            </div>
        </header>
    )
}

export default Header
