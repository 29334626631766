import React, { useEffect, useState } from "react";
import Select from "components/Select";
import Sort from "../../../../utils/sort";
import dataLayerHandlers from "utils/dataLayerHandlers";

const FormSelect = ({
  cities,
  setCommonValue,
  checkValidate,
  commonValue,
  dealerCityMos,
  isDealerRequest,
}) => {
  const [cityOptions, setCityOptions] = useState([]);
  const [dealerOptions, setDealerOptions] = useState([]);
  const [selectCity, setSelectCity] = useState();
  const [selectDealer, setSelectDealer] = useState();
  const [booleanState, setBooleanState] = useState(false);

  const onCitytChange = (e) => {
    const { value, label } = e;
    setSelectCity({ value, label });
    setSelectDealer({ value: "", label: "" });
    setCommonValue((state) => ({
      ...state,
      city: { value, validate: true },
      partner_id: {
        validate: true,
      },
    }));

    dataLayerHandlers.selectCity(
      isDealerRequest ? "RequestForm" : "PreorderForm",
      value
    );
  };
  const onDealerChange = (e) => {
    const { value, label } = e;
    setSelectDealer({ value, label });
    setCommonValue((state) => ({
      ...state,
      partner_id: {
        value,
        validate: true,
      },
    }));

    dataLayerHandlers.selectDealer(
      isDealerRequest ? "RequestForm" : "PreorderForm",
      label
    );
  };

  useEffect(
    (_) => {
      if (cities) {
        const newDeal = [];
        let citiesSort;
        let dealerSort;
        let Moscow;
        let STPiter;

        setCityOptions([]);
        citiesSort = Sort(cities, "cities");
        citiesSort.forEach((item, i) => {
          if (item.value.indexOf("Санкт-Петербург") === 0) {
            STPiter = citiesSort.splice(i, 1);
            citiesSort.unshift(STPiter[0]);
          }
        });
        citiesSort.forEach((item, i) => {
          if (item.value.indexOf("Москва") === 0) {
            Moscow = citiesSort.splice(i, 1);
            citiesSort.unshift(Moscow[0]);
          }
        });

        setCityOptions(citiesSort);

        if (citiesSort.length === 1) {
          if (!selectCity) onCitytChange(...citiesSort);
        }

        if (selectCity) {
          cities[selectCity.value].forEach((dealer) => {
            newDeal.push(dealer);
          });
          dealerSort = Sort(newDeal, "dealers");
          setDealerOptions(dealerSort);
        }

        if (newDeal.length === 1) {
          setSelectDealer(newDeal[0]);
          setCommonValue((state) => ({
            ...state,
            partner_id: {
              value: newDeal[0].value,
              validate: true,
            },
          }));
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cities, selectCity, setCommonValue]
  );

  useEffect(
    (_) => {
      if (checkValidate && commonValue["city"] && commonValue["partner_id"]) {
        commonValue["city"].validate
          ? setBooleanState(false)
          : setBooleanState(true);
        commonValue["partner_id"].validate
          ? setBooleanState(false)
          : setBooleanState(true);
      }
    },
    [checkValidate, commonValue]
  );

  return (
    <>
      <Select
        error={booleanState}
        helperText={"Выберите город"}
        onChange={onCitytChange}
        className="form__input"
        label={"Город"}
        options={cityOptions}
        value={selectCity}
        noOptionsMessage={() => "Выберите город"}
        isDisabled={dealerCityMos}
      />
      <Select
        error={booleanState}
        helperText={"Выберите дилера"}
        onChange={onDealerChange}
        className="form__input"
        label={"Дилер"}
        options={dealerOptions}
        value={selectDealer}
        noOptionsMessage={() => "Выберите город"}
        isDisabled={dealerCityMos}
      />
    </>
  );
};

export default FormSelect;
