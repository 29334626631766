import React, {useEffect, useState} from 'react'
import axios from 'axios';
import { Redirect } from 'react-router-dom'


        
const StatusPage = () => {
  const [url, setUrl] = useState('')
  useEffect(_ => {
    const strGET = window.location.search.replace( '?', '') 
    axios.get(`/api/status?${strGET}`)
      .then(res => {
        if(res.status === 200) {
          const newUrl = res.data.url.match(/fail|success/)               
          setUrl(`/${newUrl[0]}?${strGET}`)          
        }
      }).catch(err => {
        console.log(err)
      })    
  }, [])

  return (
    url !== '' ? <Redirect to={url}/> : ''    
  )
}

export default StatusPage