import React, { useState, useEffect } from "react";
import axios from "axios";
import { validateInput } from "utils";
import dataLayerHandlers from "utils/dataLayerHandlers";

let isFormValid = true;

export default (WrappedComponent) => (props) => {
  const {
    isDealerRequest,
    requestDealer,
    modelId,
    allDealers,
    preorderDealer,
    dealerCityMos,
  } = props;

  const [
    isPersonalDataDisclaimerVisible,
    setIsPersonalDataDisclaimerVisible,
  ] = useState(false);

  const [requestStatus, setRequestStatus] = useState(true);
  const [changeComponent, setChangeComponent] = useState(true);

  const [
    isCommunicationDislaimerVisible,
    setIsCommunicationDislaimerVisible,
  ] = useState(false);

  const [checkValidate, setCheckValidate] = useState(false);
  const [commonValue, setCommonValue] = useState({
    name: {
      value: "",
      validate: false,
    },
    lastname: {
      value: "",
      validate: false,
    },
    tel: {
      value: "",
      validate: false,
    },
    email: {
      value: "",
      validate: false,
    },
    "tel-email": {
      value: "",
      validate: false,
    },
    city: {
      value: "",
      validate: false,
    },
    sex: {
      value: "M",
      validate: true,
    },
    partner_id: {
      value: "",
      validate: false,
    },
    person: {
      value: false,
      validate: false,
    },
    communication: {
      value: false,
      validate: true,
    },
  });

  //Handlers
  const validateChange = () => {
    validateIsDealerRequest();
    const validateArr = [];
    for (let key in commonValue) {
      validateArr.push(commonValue[key].validate);
    }
    const valbool = validateArr.every((item) => item === true);
    isFormValid = !valbool;
    if (valbool) {
      isFormValid = !valbool;
    }
  };

  const validateIsDealerRequest = () => {
    if (isDealerRequest) {
      delete commonValue["tel"];
      delete commonValue["email"];
    } else {
      delete commonValue["tel-email"];
    }
  };

  validateChange();
  const onSubmit = (e) => {
    e.preventDefault();
    validateChange();
  };

  const sendDataForm = (model) => {
    const dealer = allDealers.filter(
      (d) => d.partner_id === commonValue.partner_id.value
    );
    setCheckValidate(true);
    if (!isFormValid) {
      delete commonValue["city"];
      const newObj = {};
      for (let item in commonValue) {
        newObj[item] = commonValue[item].value;
      }
      if (isDealerRequest) {
        const type = validateInput(commonValue["tel-email"].value, "email");
        if (type) {
          newObj.email = commonValue["tel-email"].value;
          newObj.tel = "0123456789";
        } else {
          newObj.tel = commonValue["tel-email"].value;
          newObj.email = "test@test.ru";
        }
        delete newObj["tel-email"];
        delete newObj["partner_id"];
      } else {
        let newNumbers = [...commonValue["tel"].value]
          .join(",")
          .replace(/[^\d;]/g, "")
          .split("");
        newNumbers.splice(0, 1);
        newObj.tel = newNumbers.join("");
      }

      newObj.lastname = commonValue.lastname.value;
      newObj.name = commonValue.name.value;
      newObj.model = modelId;
      newObj.id = dealer[0].id;
      const endpoint = isDealerRequest ? "request" : "order";

      const options = {
        method: "POST",
        headers: { "content-type": "application/json" },
        data: JSON.stringify(newObj),
        url: `/api/${endpoint}`,
      };

      dataLayerHandlers.submit(
        isDealerRequest ? "RequestForm" : "PreorderForm",
        model
      );

      axios(options)
        .then((data) => {
          if (data.status === 200) {
            if (!isDealerRequest) {
              // eslint-disable-next-line no-restricted-globals
              location.href = data.data.url;
            } else {
              setRequestStatus(true);
              setChangeComponent(false);
            }

            dataLayerHandlers.success(
              isDealerRequest ? "RequestForm" : "PreorderForm",
              model
            );
          }
        })
        .catch((err) => {
          console.log(err);
          setRequestStatus(false);
          setChangeComponent(false);
        });
    }
  };

  useEffect(
    (_) => {
      validateIsDealerRequest();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDealerRequest]
  );

  return (
    <WrappedComponent
      {...props}
      isPersonalDataDisclaimerVisible={isPersonalDataDisclaimerVisible}
      setIsPersonalDataDisclaimerVisible={setIsPersonalDataDisclaimerVisible}
      isCommunicationDislaimerVisible={isCommunicationDislaimerVisible}
      setIsCommunicationDislaimerVisible={setIsCommunicationDislaimerVisible}
      onSubmit={onSubmit}
      sendDataForm={sendDataForm}
      checkValidate={checkValidate}
      setCommonValue={setCommonValue}
      commonValue={commonValue}
      isDealerRequest={isDealerRequest}
      requestDealer={requestDealer}
      allDealers={allDealers}
      preorderDealer={preorderDealer}
      isFormValid={isFormValid}
      requestStatus={requestStatus}
      changeComponent={changeComponent}
      setChangeComponent={setChangeComponent}
      dealerCityMos={dealerCityMos}
    />
  );
};
