const Sort = (list, flag) =>  {
  
  const newList = [] 
  let listSort

  if(flag === 'cities') {
    for (let key in list) {   
      newList.push({
        label: key, value: key
      })                  
    }       
  }

  let sortFlag = flag === 'dealers' ? list : newList    
  listSort =  sortFlag.sort(function(a, b){
    let nameA=a.label.toLowerCase(), nameB=b.label.toLowerCase()
    if (nameA < nameB) 
      return -1
    if (nameA > nameB)
      return 1
    return 0 
  })    
                
  return listSort
}

export default Sort