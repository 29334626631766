import React from 'react';
import classNames from 'classnames';

import './style.scss';

const Button = (props) => {
    //ClassNames
    const className = classNames(
        'button',
        { disabled: props.disabled },
        props.className
    );

    return (
        <button {...props} disabled={false} className={className}>{props.children}</button>
    )
}

export default Button
