import React, {useEffect, useState} from 'react';
import NewTabLink from 'components/NewTabLink';


const BunckInfo = ({allDealers, selectDealer}) => {
  const [bank, setBanck] = useState('')
  const [hiddenBlock, setHiddenBlock] = useState(true)
  useEffect(_=> {            
    if(allDealers) {
      const bk = allDealers.find(dealer => dealer.partner_id === selectDealer.value)
      if(bk && bk.company) {        
        setBanck(bk.company.bank)
        setHiddenBlock(false)
      }           
    }    
  }, [selectDealer,allDealers])

  return (    
    <div className="bank-info" hidden={hiddenBlock}>
      <div className="bank-info__images">
        {bank === 'sberbank' &&
          <img
            src={require ('images/sberbank.png')}
            alt=""
            className="bank-info__logo"
          />}
        {bank === 'mkb' &&
          <img
            src={require ('images/mkb.png')}
            alt=""
            className="bank-info__logo"
          />}
        {bank === "alfabank" &&
          <img
            src={require ('images/alphabank.svg')}
            alt=""
            className="bank-info__logo"
          />}
        {!bank &&
          <img
            src={require ('images/sberbank.png')}
            alt=""
            className="bank-info__logo"
          />}
        <img
          src={require ('images/lock.svg')}
          alt=""
          className="bank-info__lock"
        />
      </div>
      <p className="bank-info__disclaimer">
        Вы будете перенаправлены на страницу платежа. Данные карты не будут предоставлены третьим лицам за исключением случаев, предусмотренных законодательством РФ. По указанному адресу мы вышлем информацию о совершенном платеже.
      </p>
      {bank === 'mkb' &&
        <NewTabLink
          href={window.location.origin + '/rules/mkb'}
          className="bank-info__mkb-rules"
        >
          Правила оплаты и возврата
        </NewTabLink>}

      {bank === 'sberbank' &&
      <NewTabLink
        href={window.location.origin + '/rules/sberbank'}
        className="bank-info__mkb-rules"
      >
        Правила оплаты и возврата
      </NewTabLink>}

    </div>
  );
};

export default BunckInfo;
