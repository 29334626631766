import React, {useEffect} from 'react'
import {SlideDown} from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css';
import {scroller, Element} from 'react-scroll';

const ScrollInfo = ({isPersonalDataDisclaimerVisible, isCommunicationDislaimerVisible}) => {

  const slideToDisclaimer = () => {
    scroller.scrollTo('disclaimer', {
        duration: 1000,
        delay: 0,
        smooth: true,
        offset: 0,
    });
  }

  useEffect(_ => {
    if (isPersonalDataDisclaimerVisible) {
        slideToDisclaimer();
    }
    if (isCommunicationDislaimerVisible) {
        slideToDisclaimer();
    }
  }, [isPersonalDataDisclaimerVisible,isCommunicationDislaimerVisible])
  return (
    <>
    <Element name="disclaimer" />
      <SlideDown>
          {
            isPersonalDataDisclaimerVisible
                ? (
                    <div className="bank-info">
                        <p className="bank-info__disclaimer">
                            [1] Настоящим даю согласие на&nbsp;обработку моих вышеуказанных персональных данных ООО &laquo;ФОЛЬКСВАГЕН Груп Рус&raquo; (248926, г. Калуга, ул. Автомобильная, д.&nbsp;1&nbsp;/ (почтовый адрес) адрес Филиала в&nbsp;г. Москве) 117485, г. Москва, ул. Обручева, д. 30/1) (далее&nbsp;&mdash; Оператор) <br/>
                            с&nbsp;целями: обработки моего запроса, направленного через сайт www.audi.ru, и&nbsp;коммуникации со&nbsp;мной в&nbsp;целях, связанных с&nbsp;обработкой и&nbsp;выполнением моего запроса с&nbsp;помощью различных средств связи, а&nbsp;именно посредством: <br/>
                            интернет; сообщений на&nbsp;адрес электронной почты; коротких текстовых сообщений (SMS) и&nbsp;мультимедийных сообщений (MMS) на&nbsp;номер телефона; а&nbsp;также посредством использования информационно-коммуникационных сервисов, таких как Viber, WhatsApp и&nbsp;тому подобных; телефонных звонков. <br/>
                            Я&nbsp;разрешаю совершать со&nbsp;своими персональными данными следующие действия: сбор, систематизацию, накопление, хранение (в&nbsp;электронном виде и&nbsp;на&nbsp;бумажном носителе), уточнение (обновление, изменение), использование, распространение (в&nbsp;том числе передачу) моих персональных данных третьим лицам, с&nbsp;которыми у&nbsp;Оператора имеются действующие договоры, в&nbsp;рамках которых Оператор поручает обработку персональных данных в&nbsp;вышеуказанных целях, включая трансграничную передачу персональных данных, обезличивание, блокирование, уничтожение, с&nbsp;использованием средств автоматизации и&nbsp;без использования таких средств. <br/>
                            Настоящее согласие на&nbsp;обработку моих персональных данных действует до&nbsp;момента выполнения моего запроса и&nbsp;может быть отозвано мною ранее в&nbsp;соответствии со&nbsp;статьей 9&nbsp;Федерального закона от&nbsp;27.07.2006 года &#8470;&nbsp;152-ФЗ &laquo;О&nbsp;персональных данных&raquo; посредством направления соответствующего письменного заявления по&nbsp;почтовому адресу Оператора, указанного в&nbsp;настоящем согласии, или в&nbsp;электронной форме посредством направления запроса на&nbsp;электронный адрес hotline@audi.ru.<br/>
                            ООО &laquo;ФОЛЬКСВАГЕН Груп Рус&raquo; ведет деятельность на&nbsp;территории Российской Федерации в&nbsp;соответствии с&nbsp;законодательством Российской Федерации. Предлагаемые Оператором в&nbsp;заявленных в&nbsp;настоящем согласии целях товары/услуги/работы доступны к&nbsp;получению на&nbsp;территории Российской Федерации. Мониторинг потребительского поведения лиц, находящихся за&nbsp;пределами Российской Федерации, Оператором не&nbsp;ведется.
                        </p>
                    </div>
                )
                : null
          }
      </SlideDown>
      <Element name="disclaimer" />
      <SlideDown>
          {
            isCommunicationDislaimerVisible
                ? (
                    <div className="bank-info">
                        <p className="bank-info__disclaimer">
                            [2] Настоящим даю согласие на&nbsp;обработку моих вышеуказанных персональных данных ООО &laquo;ФОЛЬКСВАГЕН Груп Рус&raquo; (248926, г. Калуга, ул. Автомобильная, д.&nbsp;1&nbsp;/ (почтовый адрес) адрес Филиала в&nbsp;г. Москве) 117485, г. Москва, ул. Обручева, д. 30/1) (далее&nbsp;&mdash; Оператор)
                            <br />
                            с&nbsp;целями: учета предоставленной информации в&nbsp;базах данных; проведения статистических исследований, а&nbsp;также исследований, направленных на&nbsp;улучшение качества продукции и&nbsp;услуг; проведения маркетинговых программ, в&nbsp;том числе, для продвижения товаров, работ, услуг на&nbsp;рынке; информирования меня о&nbsp;новых товарах и&nbsp;услугах Оператора и&nbsp;партнеров Операторов (например, посредством отправки журналов, отправки приглашений на&nbsp;презентации продуктов, сообщений о&nbsp;технических нововведениях, предстоящих работах по&nbsp;сервисному обслуживанию, условиях покупки нового автомобиля и&nbsp;т.д.) с&nbsp;помощью различных средств связи, а&nbsp;именно посредством:
                            <br />
                            интернет; сообщений на&nbsp;адрес электронной почты; коротких текстовых сообщений (SMS) и&nbsp;мультимедийных сообщений (MMS) на&nbsp;номер телефона; а&nbsp;также посредством использования информационно-коммуникационных сервисов, таких как Viber, WhatsApp и&nbsp;тому подобных; телефонных звонков.
                            <br />
                            Я&nbsp;разрешаю совершать со&nbsp;своими персональными данными следующие действия: сбор, систематизацию, накопление, хранение (в&nbsp;электронном виде и&nbsp;на&nbsp;бумажном носителе), уточнение (обновление, изменение), использование, распространение (в&nbsp;том числе передачу) моих персональных данных третьим лицам, с&nbsp;которыми у&nbsp;Оператора имеются действующие договоры, в&nbsp;рамках которых Оператор поручает обработку персональных данных в&nbsp;вышеуказанных целях, включая трансграничную передачу персональных данных, обезличивание, блокирование, уничтожение, с&nbsp;использованием средств автоматизации и&nbsp;без использования таких средств.
                            <br />
                            В&nbsp;целях проведения маркетинговых программ, в&nbsp;том числе для продвижения товаров, работ и&nbsp;услуг, ООО &laquo;ФОЛЬКСВАГЕН Груп Рус&raquo; может заключать соответствующие Договоры с&nbsp;третьими лицами относительно условий предоставления мне финансовых услуг. Настоящим я&nbsp;даю свое согласие на&nbsp;получение информации о&nbsp;предлагаемых такими третьими лицами финансовых услугах. Указанная информация может быть доведена до&nbsp;моего сведения как ООО &laquo;ФОЛЬКСВАГЕН Груп Рус&raquo;, так и&nbsp;самими компаниями-партнерами ООО &laquo;ФОЛЬКСВАГЕН Груп Рус&raquo;, предлагающими такие финансовые услуги, включая следующие: ООО &laquo;Фольксваген Банк РУС&raquo; (117485, г. Москва, ул. Обручева, д. 30/1, стр.1, ИНН: 7750005605, лицензия ЦБ&nbsp;РФ &#8470;&nbsp;3500), ООО &laquo;ФВ&nbsp;Груп Финанц&raquo; (Россия, 107045, г. Москва, ул. Трубная, 12, ИНН: 7702349370); ООО &laquo;Фольксваген Финансовые Услуги РУС&raquo; (Россия, 107045, г. Москва, ул. Трубная, 12, ИНН: 7702656396.
                            <br />
                            Настоящее согласие на&nbsp;обработку моих персональных данных действует до&nbsp;момента его отзыва.
                            <br />
                            Настоящее согласие может быть отозвано мною в&nbsp;любое время в&nbsp;соответствии со&nbsp;статьей 9&nbsp;Федерального закона от&nbsp;27.07.2006 года &#8470;&nbsp;152-ФЗ &laquo;О&nbsp;персональных данных&raquo; посредством направления соответствующего письменного заявления по&nbsp;почтовому адресу Оператора, указанного в&nbsp;настоящем согласии, или в&nbsp;электронной форме посредством направления запроса на&nbsp;электронный адрес hotline@audi.ru.
                            <br />
                            ООО &laquo;ФОЛЬКСВАГЕН Груп Рус&raquo; ведет деятельность на&nbsp;территории Российской Федерации в&nbsp;соответствии с&nbsp;законодательством Российской Федерации. Предлагаемые Оператором в&nbsp;заявленных в&nbsp;настоящем согласии целях товары/услуги/работы доступны к&nbsp;получению на&nbsp;территории Российской Федерации. Мониторинг потребительского поведения лиц, находящихся за&nbsp;пределами Российской Федерации, Оператором не&nbsp;ведется.
                        </p>
                    </div>
                )
                : null
          }
      </SlideDown>
    </>
  )
}

export default ScrollInfo