import React, { Fragment } from "react";
import withFilterLogic from "../hoc/withFilterLogic";

import FormInput from "../components/FormInput";
import FormSelect from "../components/FormSelect";
import FormCheckbox from "../components/FormCheckbox";
import FormRadio from "../components/FormRadio";
import Button from "components/Button";
import ScrollInfo from "../components/ScrollInfo";
import RequestStatus from "../components/RequestStatusText";

import "../style.scss";

const RequestDealer = ({
  isDealerRequest,
  requestDealer,
  isPersonalDataDisclaimerVisible,
  setIsPersonalDataDisclaimerVisible,
  isCommunicationDislaimerVisible,
  setIsCommunicationDislaimerVisible,
  checkValidate,
  commonValue,
  setCommonValue,
  sendDataForm,
  onSubmit,
  isFormValid,
  requestStatus,
  changeComponent,
  setChangeComponent,
  dealerCityMos,
  model,
}) => {
  const inputsPreorder = ["name", "lastname", "tel-email"],
    checks = ["person", "communication"];

  return (
    <Fragment>
      {changeComponent ? (
        <form className="form" onSubmit={onSubmit}>
          <div className="form-preorder">
            <FormRadio
              setCommonValue={setCommonValue}
              commonValue={commonValue}
              checkValidate={checkValidate}
              isDealerRequest={isDealerRequest}
              key="sex"
            />

            {inputsPreorder.map((item, i) => (
              <FormInput
                item={item}
                commonValue={commonValue}
                setCommonValue={setCommonValue}
                checkValidate={checkValidate}
                key={i}
                isDealerRequest={isDealerRequest}
                model={model}
              />
            ))}

            <FormSelect
              cities={requestDealer}
              isDealerRequest={isDealerRequest}
              setCommonValue={setCommonValue}
              checkValidate={checkValidate}
              commonValue={commonValue}
              dealerCityMos={dealerCityMos}
            />

            {checks.map((item, i) => (
              <FormCheckbox
                item={item}
                setCommonValue={setCommonValue}
                selectDealer={commonValue.partner_id}
                setIsPersonalDataDisclaimerVisible={
                  setIsPersonalDataDisclaimerVisible
                }
                setIsCommunicationDislaimerVisible={
                  setIsCommunicationDislaimerVisible
                }
                isPersonalDataDisclaimerVisible={
                  isPersonalDataDisclaimerVisible
                }
                isCommunicationDislaimerVisible={
                  isCommunicationDislaimerVisible
                }
                isDealerRequest={isDealerRequest}
                commonValue={commonValue}
                checkValidate={checkValidate}
                key={i}
              />
            ))}

            <Button
              disabled={isFormValid}
              onClick={() => sendDataForm(model)}
              className="form__submit"
            >
              Отправить запрос
            </Button>
          </div>
          <ScrollInfo
            isPersonalDataDisclaimerVisible={isPersonalDataDisclaimerVisible}
            isCommunicationDislaimerVisible={isCommunicationDislaimerVisible}
          />
        </form>
      ) : (
        <RequestStatus
          status={requestStatus}
          setChangeComponent={setChangeComponent}
        />
      )}
    </Fragment>
  );
};

export default withFilterLogic(RequestDealer);
