import React from 'react';
import classNames from 'classnames';

import './style.scss';

const Checkbox = (props) => {
    //ClassNames
    const className = classNames(
        'checkbox',
        props.className,
        { error: props.error },
        { hidden: props.hidden }
    );

    return (
        <label className={className}>
            <input {...props} error="" children={null} className="checkbox__input" type="checkbox" />
            <div className="checkbox__label"></div>
            {props.children}
        </label>
    )
}

export default Checkbox
