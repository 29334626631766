export const sendEventToDataLayer = (
  eventCategory,
  eventAction,
  eventLabel = undefined
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "send_ga",
    eventCategory,
    eventAction,
    eventLabel,
  });
};

export default null;
