import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import InputMask from 'react-input-mask';

import './style.scss';

const TelInputMask = (props) => (
    <InputMask
        {...props}
        mask="+7 (999) 999-99-99"
    />
)

const Input = (props) => {
    //State
    const [isFocused, setIsFocused] = useState(false);

    //ClassNames
    const className = classNames(
        'input',
        props.className
    )

    //Handlers
    const onFocus = (e) => {
        setIsFocused(true);
        if (typeof props.onFocus === 'function') {
            props.onFocus(e);
        }
    }
    const onBlur = (e) => {
        setIsFocused(false);
        if (typeof props.onBlur === 'function') {
            props.onBlur(e);
        }
    }

    //Data
    let InputProps;

    if (props.type === 'tel') {
        InputProps = {
            inputComponent: TelInputMask,
        };
    }

    return (
        <div hidden={props.hidden} className={className}>
            <TextField
                {...props}
                helperText={props.error ? props.helperText : ' '}
                error={isFocused ? false : props.error}
                onFocus={onFocus}
                onBlur={onBlur}
                fullWidth
                InputProps={InputProps}
            />
        </div>
    )
}

Input.propTypes = {
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
}

export default Input
