import React from 'react';
import classNames from 'classnames';

const NewTabLink = (props) => {
    //ClassNames
    const className = classNames(
        props.className
    )

    return (
        <a {...props} target="_blank" href={props.href} rel="noopener noreferrer" className={className}>
            {props.children}
        </a>
    )
}

export default NewTabLink
