import React, { useState, useEffect } from "react";
import Input from "components/Input";

import { validateInput } from "utils";
import dataLayerHandlers from "utils/dataLayerHandlers";

const FormInput = ({
  item,
  setCommonValue,
  commonValue,
  checkValidate,
  isDealerRequest,
  model,
}) => {
  const [statusItemForm, setStatusItemForm] = useState({});
  const [booleanState, setBooleanState] = useState(false);

  const onInputChange = (type) => (e) => {
    const { value } = e.target;
    setBooleanState(!validateInput(value, type));
    if (item === "tel-email") {
      setBooleanState(!validateInput(value, "text"));
    }
  };

  const onChangeBlur = (type) => (e) => {
    const { value, name } = e.target;
    setBooleanState(!validateInput(value, type));
    setCommonValue((state) => ({
      ...state,
      [name]: {
        value,
        validate: !booleanState,
      },
    }));

    switch (type) {
      case "name":
        return dataLayerHandlers.inputName(
          isDealerRequest ? "RequestForm" : "PreorderForm",
          model
        );
      case "lastname":
        return dataLayerHandlers.inputLastname(
          isDealerRequest ? "RequestForm" : "PreorderForm",
          model
        );
      case "tel":
        return dataLayerHandlers.inputPhone(
          isDealerRequest ? "RequestForm" : "PreorderForm",
          model
        );
      case "email":
        return dataLayerHandlers.inputEmail(
          isDealerRequest ? "RequestForm" : "PreorderForm",
          model
        );
      case "tel-email":
        return dataLayerHandlers.inputContacts(
          isDealerRequest ? "RequestForm" : "PreorderForm",
          model
        );
      default:
        return () => {};
    }
  };

  useEffect(
    (_) => {
      if (checkValidate && commonValue[item]) {
        commonValue[item].validate
          ? setBooleanState(false)
          : setBooleanState(true);
      }

      switch (item) {
        case "name":
          return setStatusItemForm({
            label: "Имя",
            type: "text",
            name: "name",
          });
        case "lastname":
          return setStatusItemForm({
            label: "Фамилия",
            type: "text",
            name: "lastname",
          });
        case "tel":
          return setStatusItemForm({
            label: "Телефон",
            type: "tel",
            name: "tel",
          });
        case "email":
          return setStatusItemForm({
            label: "E-mail",
            type: "email",
            name: "email",
          });
        case "tel-email":
          return setStatusItemForm({
            label: "Телефон или E-mail",
            type: "tel-email",
            name: "tel-email",
          });
        default:
          return {};
      }
    },
    [item, commonValue, checkValidate]
  );

  return (
    <Input
      error={booleanState}
      onBlur={onChangeBlur(item)}
      helperText="Заполните поле корректно"
      onChange={onInputChange(item)}
      className="form__input"
      type={statusItemForm.type}
      name={statusItemForm.name}
      label={statusItemForm.label}
    />
  );
};

export default FormInput;
